import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/utils/auth'
import { Message } from 'element-ui'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/login/Login.vue')
  },
  {
    path: '/main',
    name: 'main',
    component: () => import('@/views/management/common/Common.vue'),
    redirect: '/main/data',
    children: [
      {
        path: '/main/data',
        name: 'data',
        component: () => import('@/views/management/data/Data.vue')
      },
      {
        path: '/main/introduce',
        name: 'introduce',
        component: () => import('@/views/management/pavilion/introduce/Introduce.vue')
      },
      {
        path: '/main/article',
        name: 'article',
        component: () => import('@/views/management/pavilion/article/Article.vue')
      },
      {
        path: '/main/person',
        name: 'person',
        component: () => import('@/views/management/subscribe/person/Person.vue')
      },
      {
        path: '/main/team',
        name: 'team',
        component: () => import('@/views/management/subscribe/team/Team.vue')
      },
      {
        path: '/main/user',
        name: 'user',
        component: () => import('@/views/management/user/User.vue')
      },
      {
        path: '/main/online',
        name: 'online',
        component: () => import('@/views/management/memorial/online/Online.vue')
      },
      {
        path: '/main/record',
        name: 'record',
        component: () => import('@/views/management/memorial/record/Record.vue')
      },
      {
        path: '/main/card',
        name: 'card',
        component: () => import('@/views/management/card/Card.vue')
      },
      {
        path: '/main/exchange',
        name: 'exchange',
        component: () => import('@/views/management/integral/exchange/Exchange.vue')
      },
      {
        path: '/main/guideline',
        name: 'guideline',
        component: () => import('@/views/management/integral/guideline/Guideline.vue')
      },
      {
        path: '/main/vr',
        name: 'vr',
        component: () => import('@/views/management/vr/VR.vue')
      }
    ]
  },
  {
    path: '/mine',
    name: 'mine',
    component: () => import('@/views/management/mine/Mine.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // 是否登录
  if (to.name !== 'login') {
    if (getToken()) {
      next()
    } else {
      Message.closeAll()
      Message({
        message: '请先登陆',
        type: 'error',
        duration: 5000
      })
      next({ path: '/' })
    }
  } else {
    next()
  }
})

// 解决使用router.push路由重复报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
