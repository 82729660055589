import Vue from 'vue'
import router from './router'
import store from './store'
import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/base.css'
import locale from 'element-ui/lib/locale/lang/en' // lang i18n
import 'xe-utils'
/* import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css' */
import 'nprogress/nprogress.css'
// 引入moment
import moment from 'moment'
// 设定moment区域为中国
import 'moment/locale/zh-cn'
// 引入echarts
import * as echarts from 'echarts'
import App from './App'

moment.locale('zh-cn')

Vue.use(ElementUI, locale)
// Vue.use(VXETable)
Vue.prototype.$echarts = echarts
Vue.prototype.$moment = moment
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
